import { useEffect, useMemo } from 'react';
import { onAuthStateChanged } from 'firebase/auth';

import { fetchBillingSubscriptions } from 'api/billing';
import { auth } from 'lib/firebase/firebase-config';
import { clearStorage, getStorageItem } from 'utils/storage';
import { useAuthStore } from 'store/auth';
import { getMe, postLogin } from 'api/user';
import { reportAnalyticsEvent } from 'utils/analytics';
import { useModalsStore } from 'store/modals';
import Cookies from 'js-cookie';
import { showToast } from 'components/ui/CustomToast';
import { isCrushAppMobile, isCrushAppIOS, isCrushAppAndroid } from 'utils/userAgentCheck';

export const useAuth = () => {
  const { user, setUser, setIsLoading, setIsLogged } = useAuthStore();
  const { setIsOpenAuthModal } = useModalsStore();

  const subscribeToAuthChanges = () =>
    onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        setIsLoading(true);
        const isAppMobile = isCrushAppMobile();
        const previousUser = getStorageItem('user');

        if (firebaseUser) {
          setIsLogged(true);
          postLogin().catch(console.error);  // Intentionally not await.
          const user = await getMe();
          const token = await firebaseUser.getIdToken();
          Cookies.set('token', token);
          try {
            window.posthog.identify(user?.uuid, { email: user?.email, name: user?.custom_attributes.name });
          } catch (error) {}
          await fetchBillingSubscriptions();
        } else if (!isAppMobile && previousUser) {
          const { logoutRedirect } = useAuthStore.getState();
          if (logoutRedirect) {
            handleLogout(logoutRedirect);
          } else {
            // Wait a bit to see if Firebase recovers on its own
            console.log('Unexpected auth state - waiting to confirm...');
            reportAnalyticsEvent('Auth Error');
            setTimeout(() => {
              if (getStorageItem('user')) {
                reportAnalyticsEvent('Token Wait recovered');
              }
              else {
                reportAnalyticsEvent('Token Wait error');
                setIsOpenAuthModal(true);
              }
            }, 5000);
          }
        }
      } catch (error) {
        console.error('Auth state change error:', error);
      } finally {
        setIsLoading(false);
      }
    });

  useEffect(() => {
    // Set user from local storage
    const user = getStorageItem('user');
    if (user) setUser(user);

    // Listen for token issues before they cause logout
    const tokenUnsubscribe = auth.onIdTokenChanged(
      async (user) => {
        try {
          console.log('Token state changed:', user ? 'valid' : 'invalid');
          if (!user) {
            reportAnalyticsEvent('token_error', {
              reason: 'token_state_changed',
              type: 'invalidated'
            });
          }
        } catch (error) {
          console.error('Token state change handler error:', error);
        }
      },
      (error: any) => {
        try {
          console.error('Token error:', {
            code: error?.code,
            message: error?.message
          });
          reportAnalyticsEvent('token_error', {
            reason: error?.code || 'unknown',
            message: error?.message,
            type: 'error'
          });
        } catch (handlerError) {
          console.error('Token error handler error:', handlerError);
        }
      }
    );

    // Normal auth state changes
    const authUnsubscribe = subscribeToAuthChanges();

    return () => {
      tokenUnsubscribe();
      authUnsubscribe();
    };
  }, []);

  const isUserDetailsPopulated = useMemo(() => {
    if (!user) return false;

    const { custom_attributes } = user;

    return Boolean(custom_attributes?.gender) &&
      Boolean(custom_attributes?.name) &&
      Boolean(custom_attributes?.accept_tos_18)
      ? false
      : true;
  }, [user]);

  return {
    isUserDetailsPopulated
  };
};

export const handleLogout = (redirectUrl: string) => {
  const { setUser, setIsLogged } = useAuthStore.getState();

  showToast({ message: 'You have now signed out.', type: 'success' });
  reportAnalyticsEvent('Logout Completed');

  setUser(null);
  setIsLogged(false);
  clearStorage();
  Cookies.remove('userID');
  Cookies.remove('token');
  window.posthog.reset();

  window.location.href = redirectUrl || '/';
};
